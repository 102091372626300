import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import schoollogo from "../../image/logo/schoollogo2.png";
import bulbLogo from "../../image/logo/bulb.png";
import rocketLogo from "../../image/logo/rocket.png";


import "./Content.css";

function Vision(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div style={{ marginBottom: "-6rem" }}>
        <div
          style={{
            width: "204.11px",
            height: "204.64px",
            marginLeft: "74%",
            marginTop: "-7rem",
            background: "#F919848A",
            borderRadius: "0 0 100px 100px",
          }}
        ></div>
        <div
          style={{
            width: "204.11px",
            height: "204.64px",
            marginLeft: "83.5%",
            marginTop: "-11rem",
            background: "rgb(1 153 227 / 50%)",
            borderRadius: "0 0 100px 100px",
          }}
        ></div>
      </div>
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>

        <div>
          <br />
          <div className={`f-2 f-500 mb-3`} style={{ color: "#009ae2" }}>
            Vision & Mission
          </div>
          <div className="vision-container">
            <div className="vision-logo">
              <img src={schoollogo} className="visionleft" alt="Vision Logo Left" />
            </div>
            <div className="vision-content">
              <h2 className="vission-heading">VISION</h2>
              <p className="vision-text">
                Our vision at Hermann Gmeiner School is to empower students through a
                transformative blend of academic excellence, innovation, and holistic
                development. We aspire to create a dynamic learning environment where
                education is further enriched by technology and creativity. By nurturing
                each child's unique potential, we aim to develop lifelong learners who are
                not only academically accomplished but also socially responsible,
                compassionate, and prepared to lead in a rapidly changing world. Our
                ultimate goal is to inspire our students to become confident, ethical, and
                engaged citizens who contribute positively to society.
              </p>
            </div>
            <div className="vision-logo vision-right">
              <img src={bulbLogo} className="vision-right" alt="Bulb logo" />
            </div>
          </div>

          <br />
          <br />
          <div className="vision-container">
            <div className="vision-logo">
              <img src={schoollogo} className="visionleft" alt="Vision Logo Left" />
            </div>
            <div className="vision-content">
              <h2 className="vission-heading">MISSION</h2>
              <p className="vision-text">
                Our mission at Hermann Gmeiner School is to bring our vision to life by delivering a well-rounded, future-focused education.
                We are dedicated to fostering academic excellence through a modern curriculum that integrates technology, creativity, and experiential learning.
                By providing opportunities in sports and co-curricular activities, we aim to develop students' physical, emotional, and social well-being.
                We commit to cultivating an inclusive and nurturing environment where educators and students collaborate to enhance learning experiences.
                Through personalized guidance, diverse learning approaches, and character-building initiatives, we empower our students to grow into responsible and compassionate citizens.
              </p>
            </div>
            <div className="vision-logo vision-right">
              <img src={rocketLogo} className="vision-right" alt="Bulb logo" />
            </div>
          </div>
        </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Vision;
